import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../../store/hooks'
import Icon from '../Icon'
import './index.less'
import { noticesysList } from '../../servers/V1/home'
import { imgUrl } from '../../utils/staticUrl'
import { Tooltip } from 'antd'
import { useHistory } from 'react-router-dom'
const PassFooter = () => {
  const { lng } = useAppSelector((store) => store.global)
  const [helpList, setHelpList] = useState([])
  const history = useHistory()
  const share = [
    {
      name: '微信',
      img: imgUrl('aacCommon/wechat_img.jpg'),
      icon: 'icon-wechat',
    },
  ]
  // const footerMenu = [
  //   {
  //     title: '关于',
  //     list: [
  //       {
  //         name: '关于',
  //         url: 'https://cdc.v1.cn/aboutUs',
  //       },
  //       {
  //         name: '产品与服务',
  //         url: 'https://cdc.v1.cn/issuance',
  //       },
  //       {
  //         name: '联系我们',
  //         url: '',
  //       },
  //       {
  //         name: '品牌指南',
  //         url: '',
  //       },
  //     ],
  //   },
  //   {
  //     title: '条款',
  //     list: [
  //       {
  //         name: '用户服务协议',
  //         url: '',
  //       },
  //       {
  //         name: '商户服务协议',
  //         url: '',
  //       },
  //       {
  //         name: '支持者协议',
  //         url: '',
  //       },
  //       {
  //         name: '其他协议',
  //         url: '',
  //       },
  //     ],
  //   },
  // ]

  const getNoticesysList = async () => {
    try {
      const res = await noticesysList({ group_key: 'pc' })
      if (res && res.code == 0) {
        // console.log(res.data.list, '马靖')
        let newList = res.data.list.map((item) => {
          return { key: item.key.split('-')[0], group_key: item.group_key, title: item.title, _id: item._id }
        })
        let obj = {}
        console.log(newList, 'newList')
        newList.forEach((item, index) => {
          console.log(index, 'index')
          let { key } = item
          if (!obj[key]) {
            if (key == 'help') {
              obj[key] = {
                key,
                children: [],
                name: '帮助中心',
              }
            }

            if (key == 'shop') {
              obj[key] = {
                key,
                children: [],
                name: '平台协议',
              }
            }

            if (key == 'term') {
              obj[key] = {
                key,
                children: [],
                name: '条款',
              }
            }
          }
          obj[key].children.push(item)
        })
        let dataPush = Object.values(obj) // 最终输出

        var array = dataPush
        array.unshift({
          name: '关于',
          key: 'about',
          children: [
            {
              title: '关于非遗（文创）数字资源库',
              key: 'about',
            },
            {
              title: '产品与服务',
              key: 'service',
            },
            // {
            //   title: '联系我们',
            //   key: '',
            // },
          ],
        })
        console.log(array, '最后')

        setHelpList(array)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const toMenu = (item) => {
    console.log(item, 'ni1')
    if (item.key == 'about') {
      console.log('打开新的窗口')
      window.location.href = `${process.env.REACT_APP_URL}aboutUs`
    } else if (item.key == 'service') {
      window.location.href = `${process.env.REACT_APP_URL}issuanceWork`
    } else {
      // history.push({ pathname: '/agree', state: { _id: item._id } })
      if(item.file){
        window.open(item.file)
      } else {
        item._id && history.push({ pathname: `/agree/${item._id}` })
      }
    }
  }

  useEffect(() => {}, [lng])

  useEffect(() => {
    getNoticesysList()
  }, [])
  return (
    <div id="pass_footer">
      <div className="content">
        <div className="footer_content">
          <div className="content">
            {helpList &&
              helpList.map((item, index) => (
                <div className="footer_menu" key={index}>
                  <div className="menu_title">{item.name}</div>
                  {item.children &&
                    item.children.map((it, id) => (
                      <div
                        className="menu_list"
                        key={id}
                        onClick={() => {
                          toMenu(it)
                        }}
                      >
                        <div className="list">
                          {it.url ? (
                            <a href={it.url} target={'blank'}>
                              <div className="menu_name">{it.title}</div>
                            </a>
                          ) : (
                            <div className="menu_name">{it.title}</div>
                          )}
                          {it.children && <Icon type="icon-down" />}
                        </div>
                        {/* <div className="list_children">
                          {it.children &&
                            it.children.map((i, d) => (
                              <div className="child" key={d}>
                                {i.url ? (
                                  <a href={i.url} target={'blank'}>
                                    <div className="children">{i.title}</div>
                                  </a>
                                ) : (
                                  <div className="children">{i.title}</div>
                                )}
                              </div>
                            ))}
                        </div> */}
                      </div>
                    ))}
                </div>
              ))}
          </div>
          <div className="share">
            {share &&
              share.map((item, index) => (
                <Tooltip
                  placement="top"
                  overlayClassName="share_tooltip"
                  title={
                    <>
                      <img src={item.img} />
                    </>
                  }
                  key={index}
                >
                  <div className="share_box" key={index}>
                    <Icon className="share_icon" type={item.icon} />
                    <p>{item.name}</p>
                  </div>
                </Tooltip>
              ))}
          </div>
        </div>
        <div className="footer_records">
          <div className="note">{'非遗（文创）数字资源库 '}</div>
          <div className="record">
            京ICP备 <a href="https://beian.miit.gov.cn/#/Integrated/index">10014938号-4</a>；
            <a href="https://www.beian.gov.cn/portal/index.do">公京网安备11010502041088</a>；违法和不良信息举报电话：010-65055636；举报邮箱：zgctgy@wodsy.com
          </div>
          {/* <div className="copyright">
            {'Copyright 2016-2017 Beijing Culture Equity Exchange CO., LTD, All Rights Reserved'}
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default PassFooter
