import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import { Input } from 'antd'

import Icon from '../Icon'
import './index.less'
import { useTranslation } from 'react-i18next'

const Search = ({
  className, //可自定义类名
  width, //宽
  placeholder = '',
  onSearch, //方法
  values = '', //默认值
  addonAfter = '', //后置标签
  addonBefore = '', //前置标签
  iconTypePrefix = '', //前缀图标
  iconTypeSuffix = 'icon-search', //后缀图标
  ...props
}) => {
  const [value, setValue] = useState(values || '')
  const { t } = useTranslation()
  const onClick = () => {
    onSearch && onSearch(value)
  }
  useEffect(() => {
    if (!values) {
      setValue('')
    } else {
      setValue(values)
    }
  }, [values])
  return (
    <div {...props} className={classnames('cust-search', className)} style={{ width: width ? width + 'px' : '' }}>
      <Input
        value={value}
        bordered={false}
        onPressEnter={onClick}
        onChange={(e) => {
          setValue(e.target.value)
          onSearch(e.target.value)
        }}
        placeholder={placeholder || t('common.search')}
        addonAfter={addonAfter}
        addonBefore={addonBefore}
        prefix={<Icon type={iconTypePrefix} className="search-icon" />}
        suffix={<Icon onClick={onClick} type={iconTypeSuffix} className="search-icon" />}
      />
    </div>
  )
}

export default Search
