import { get } from '_src/utils/http'

// // 合作方
// export function getPartners(data) {
//   return get('/api/pass_card/public/partner', data).then((res) => {
//     return res
//   })
// }

// 首页信息
export function getHome(data) {
  return get('/api/base/home/data', data).then((res) => {
    return res
  })
}

export function noticesysList(data) {
  return get('/api/noticesys/list', data).then((res) => {
    return res
  })
}

// 须知详情
export function noticesysDetail(data) {
  return get('/api/noticesys/detail', data).then((res) => {
    return res
  })
}

//  banner
export function bannerList(data) {
  return get('/api/base/banner/list', data).then((res) => {
    return res
  })
}
