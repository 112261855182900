// import React from 'react'
// import Icon from '../Icon'

export const marketUrl = '/'
export const navs = (t, isMobile = false) => {
  const defaultNavs = [
    {
      label: t('header.Home'),
      url: '/home',
      key: '/home',
    },
    {
      label: t('header.Issuance'),
      url: '/issuanceWork',
      key: '/issuanceWork',
    },
    {
      label: t('header.Registration'),
      url: '/registration',
      key: '/registration',
    },
    {
      label: t('header.Publisher'),
      url: '/prefecture',
      key: '/prefecture',
    },
    {
      label: t('header.Verification'),
      url: '/verification',
      key: '/verification',
    },
    // {
    //   label: t('header.CopyrightIndex'),
    //   url: '/copyrightIndex',
    //   key: '/copyrightIndex',
    // },
    // {
    //   label: t('header.Transaction'),
    //   url: '/transaction',
    //   key: '/transaction',
    // },
    // {
    //   label: t('header.AboutUs'),
    //   url: '/aboutUs',
    //   key: '/aboutUs',
    // },
  ]
  if (isMobile) {
    const BuyAac = {
      // label: t('header.Buy_aac'),
      // url: '/buyAac',
      // key: '/buyAac',
    }
    return [...defaultNavs, BuyAac]
  }

  return defaultNavs
}
