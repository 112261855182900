const routes = () => [
  // path: '/home/:join?/:invite_code?',携带参数方式
  /**
   * @descrition 携带参数方式
   * @params {String} 当前参数
   * @identification {?} ?为参数可选
   * @hideFooter hideFooter:true 不显示Footer
   */
  {
    path: '/home',
    component: () => import('../pages/home'),
    exact: true,
    children: [
      //首页>公告
      {
        path: '/home/notice',
        component: () => import('../pages/home/notice'),
        exact: true,
      },
      //商户成功页面
      {
        path: '/home/submitSucceed',
        component: () => import('../pages/home/submitSucceed'),
        exact: true,
      },
    ],
  },
  {
    path: '/issuance',
    component: () => import('../pages/issuance'),
    exact: true,
  },
  {
    path: '/issuanceWork',
    component: () => import('../pages/issuanceWork'),
    exact: true,
  },
  {
    path: '/registration',
    component: () => import('../pages/registration'),
    exact: true,
    children: [
      {
        path: '/registration/workForm',
        component: () => import('../pages/registration/form/workForm'),
        exact: true,
      },
      {
        path: '/registration/authorForm',
        component: () => import('../pages/registration/form/authorForm'),
        exact: true,
      },
      {
        path: '/registration/ownerForm',
        component: () => import('../pages/registration/form/ownerForm'),
        exact: true,
      },
    ],
  },
  {
    path: '/transaction',
    component: () => import('../pages/transaction'),
    exact: true,
  },
  {
    path: '/prefecture',
    component: () => import('../pages/publisher'),
    exact: true,
    children: [
      //商户入住流程页
      {
        path: '/prefecture/settled',
        component: () => import('../pages/registration/form/MerchantsForm'),
        exact: true,
      },
      {
        path: '/prefecture/details',
        component: () => import('../pages/publisher/details'),
        exact: true,
      },
    ],
  },
  {
    path: '/copyrightIndex',
    component: () => import('../pages/copyrightIndex'),
    exact: true,
  },
  {
    path: '/verification/:code?',
    component: () => import('../pages/verification'),
    exact: true,
  },
  {
    path: '/aboutUs',
    component: () => import('../pages/aboutUs'),
    exact: true,
  },
  //登录
  {
    path: '/login',
    component: () => import('../pages/login'),
    exact: true,
    children: [
      //注册
      {
        path: '/login/register',
        component: () => import('../pages/login/register'),
        exact: true,
      },
      //微信扫码
      {
        path: '/login/scanCode',
        component: () => import('../pages/login/scancode'),
        exact: true,
      },
      //重置密码
      {
        path: '/login/setpassword',
        component: () => import('../pages/login/setpassword'),
        exact: true,
      },
    ],
  },

  // 作品详情
  {
    path: '/workDetails/page/:id/:type/:market?',
    component: () => import('../pages/workDetails'),
    exact: true,
    children: [
      {
        path: '/workDetails/authorInfo',
        component: () => import('../pages/workDetails/authorInfo'),
        exact: true,
      },
      {
        path: '/workDetails/payment',
        component: () => import('../pages/workDetails/payment'),
        exact: true,
      },
      {
        path: '/workDetails/margin',
        component: () => import('../pages/workDetails/margin'),
        exact: true,
      },
      {
        path: '/workDetails/auctionRecord',
        component: () => import('../pages/workDetails/auctionRecord'),
        exact: true,
      },
    ],
  },
  // 用户中心
  // 作品详情
  {
    path: '/personalCenter',
    component: () => import('../pages/personalCenter'),
    exact: true,
    children: [
      {
        path: '/personalCenter/recharge',
        component: () => import('../pages/personalCenter/myAccount/recharge'),
        exact: true,
      },
      {
        path: '/personalCenter/paymentChannel',
        component: () => import('../pages/personalCenter/myAccount/paymentChannel'),
        exact: true,
      },
      {
        path: '/personalCenter/cashier',
        component: () => import('../pages/personalCenter/myAccount/cashier'),
        exact: true,
      },
      {
        path: '/personalCenter/results/:typePay?/:code?',
        component: () => import('../pages/personalCenter/myAccount/results'),
        exact: true,
      },
      {
        path: '/personalCenter/cashWithdrawal',
        component: () => import('../pages/personalCenter/myAccount/cashWithdrawal'),
        exact: true,
      },
      {
        path: '/personalCenter/cashWithdrawalStatus',
        component: () => import('../pages/personalCenter/myAccount/cashWithdrawalStatus'),
      },
      // 我的版权》履约详情
      {
        path: '/personalCenter/authDetails',
        component: () => import('../pages/personalCenter/myAssetsDetails'),
        exact: true,
      },
      {
        path: '/personalCenter/results',
        component: () => import('../pages/personalCenter/myAccount/results'),
        exact: true,
      },
    ],
  },
  // 底部的协议
  {
    // /workDetails/page/:id/:type/:market?
    path: '/agree/:_id',
    component: () => import('../pages/agree'),
    exact: true,
  },
]
export default routes
