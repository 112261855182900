import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from './store' // 引入store
import App from './App' //引入处理路由文件
import './styles/index.less'
import './i18n'
import 'moment/locale/zh-cn' //解决中文显示问题 !!!关键代码
class Root extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <App />
      </Provider>
    )
  }
}

ReactDOM.render(<Root />, document.getElementById('root'))

//  实时刷新
if (module.hot) {
  module.hot.accept()
}

// 移动端调试工具
const { NODE_ENV, 
  // REACT_APP_MOBILE_URL, 
  REACT_APP_ENV } = process.env
/* vconsole 生产环境开启需要单独写 */
if (NODE_ENV != 'production' && document.documentElement.clientWidth < 750) {
  const vconsole = require('vconsole')
  new vconsole()
}
console.log(window.location.pathname)
if (
  (REACT_APP_ENV == 'TEST' || REACT_APP_ENV == 'PROD') &&
  document.documentElement.clientWidth < 750 &&
  window.location.pathname == '/home'
) {
  // window.location.href = REACT_APP_MOBILE_URL
}
