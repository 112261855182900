import { post } from '_src/utils/http'

//登录接口
export function getLogin(data) {
  return post('/api/base/user/login', data).then((res) => {
    return res
  })
}

//注册接口
export function getRegister(data) {
  return post('/api/base/user/register', data).then((res) => {
    return res
  })
}

//验证码接口
export function getVcode(data) {
  return post('/api/base/user/send_sms', data).then((res) => {
    return res
  })
}

//退出登录接口
export function getLoginOut(data) {
  return post('/api/base/user/logout', data).then((res) => {
    return res
  })
}

// 重置密码
export function getReset(data) {
  return post('/api/base/user/password/reset', data).then((res) => {
    return res
  })
}
// 微信登录
export function getLoginWeiXin(data) {
  return post('/api/base/user/login/weixin', data).then((res) => {
    return res
  })
}

// // 支付宝登录url
// export function getLoginAliPayUrl(data) {
//   return get('/api/base/user/login/alipay/url', data).then((res) => {
//     return res
//   })
// }
// 支付宝登录
export function getLoginAliPay(data) {
  return post('/api/base/user/login/alipay', data).then((res) => {
    return res
  })
}
