import { get, post } from '_src/utils/http'
// 获取余额（老,没用）
export function getAmount(data) {
  return get('/ucenter/cny/census/current/amount', data).then((res) => {
    return res
  })
}

// 4.1 个人信息
export function getUserInfo(data) {
  return get('/api/client/user/info', data).then((res) => {
    return res
  })
}
// 4.2 通过原密码修改密码
export function passwordUpdate(data) {
  return post('/api/client/user/password/update', data).then((res) => {
    return res
  })
}
// 4.3 修改个人信息（头像和昵称）
export function infoUpdate(data) {
  return post('/api/client/user/info/update', data).then((res) => {
    return res
  })
}
// 4.4 实名认证
export function idcardAuthentication(data) {
  return post('/api/client/user/idcard/authentication', data).then((res) => {
    return res
  })
}
// 4.5 修改支付密码
export function payPasswordUpdat(data) {
  return post('/api/client/user/pay_password/update', data).then((res) => {
    return res
  })
}
// 4.6 零钱包充值
export function walletRecharge(data) {
  return post('/api/client/wallet/recharge', data).then((res) => {
    return res
  })
}

// 4.7零钱包提现前的检查
export function walletWithdraw(data) {
  return post('/api/client/wallet/withdraw', data).then((res) => {
    return res
  })
}
// 4.8 零钱包收支记录
export function recordListData(data) {
  return get('/api/client/wallet/record/list', data).then((res) => {
    return res
  })
}

// 4.9 零钱包收支记录列表
// export function recordList(data) {
//   return get('/api/client/wallet/record/list', data).then((res) => {
//     return res
//   })
// }
// 4.10 零钱包收支记录导出
export function recordExport(data) {
  return get('/api/client/wallet/record/export', data).then((res) => {
    return res
  })
}
// 4.11  我的账户详情
export function walletInfo(data) {
  return get('/api/client/wallet/info', data).then((res) => {
    return res
  })
}
// 4.12 绑定银行卡
export function cardBind(data) {
  return post('/api/client/wallet/card/bind', data).then((res) => {
    return res
  })
}
// 4.13 解绑银行卡
export function cardUnbind(data) {
  return post('/api/client/wallet/card/unbind', data).then((res) => {
    return res
  })
}
// 4.14 作品类型列表
export function workTypeList(data) {
  return get('/api/base/work_type/list', data).then((res) => {
    return res
  })
}
// 4.15 我的认购订单列表（一级）
export function orderList(data) {
  return get('/api/client/activity/order/list', data).then((res) => {
    return res
  })
}
// 4.16 我的版权列表
export function assetList(data) {
  return get('/api/client/asset/list', data).then((res) => {
    return res
  })
}
// 4.17 我的履约列表
export function performList(data) {
  return get('/api/client/asset/perform/list', data).then((res) => {
    return res
  })
}
// // 4.18 版权详情
// export function assetDetail(data) {
//   return get('/api/client/asset/detail', data).then((res) => {
//     return res
//   })
// }
// 4.19 公告通知列表
export function noticeList(data) {
  return get('/api/client/user/notice/list', data).then((res) => {
    return res
  })
}
// 4.20 公告通知详情
export function noticeDetail(data) {
  return get('/api/client/user/notice/detail', data).then((res) => {
    return res
  })
}

// 4.7 零钱包提现前的检查
export function withdrawCheck(data) {
  return post('/api/client/wallet/withdraw/check', data).then((res) => {
    return res
  })
}
// 获取版权类型过滤器
export function filterList() {
  return get('/api/base/asset_filter/list').then((res) => {
    return res
  })
}

// 1.7 用户手动取消订单
export function orderCancel(data) {
  return post('/api/client/activity/order/cancel', data).then((res) => {
    return res
  })
}
// 1.8 用户支付订单
export function orderPay(data) {
  return post('/api/client/activity/order/pay', data).then((res) => {
    return res
  })
}
// 4.20 版权履约
export function assetPerform(data) {
  return post('/api/client/asset/perform', data).then((res) => {
    return res
  })
}
// 2.6 路演列表
export function reoadShowList() {
  return get('/api/base/road_show/list').then((res) => {
    return res
  })
}
// 4.6 重置支付密码
export function payPasswordReset(data) {
  return post('/api/client/user/pay_password/reset', data).then((res) => {
    return res
  })
}
// 4.8 零钱包充值-杉德
export function rechargeSandpay(data) {
  return post('/api/client/wallet/recharge/sandpay', data).then((res) => {
    return res
  })
}
// 4.8 零钱包充值-微信
export function rechargeWxpay(data) {
  return post('/api/client/wallet/recharge/wxpay', data).then((res) => {
    return res
  })
}
// 4.9 零钱包充值结果查询
export function rechargeCheck(data) {
  return post('/api/client/wallet/recharge/check', data).then((res) => {
    return res
  })
}
// 4.10 支付结果查询
export function payCheck(data) {
  return post('/api/client/wallet/pay/check', data).then((res) => {
    return res
  })
}
// 4.24 我的单个版权信息
export function assetInfo(data) {
  return get('/api/client/asset/info', data).then((res) => {
    return res
  })
}
// 12. 用户中心我的二级订单列表
export function assetOrderList(data) {
  return get('/api/client/asset/order/list', data).then((res) => {
    return res
  })
}

// 8. 创建订单
export function orderCreate(data) {
  return post('/api/client/asset/order/create', data).then((res) => {
    return res
  })
}
// 9. 用户手动取消订单
export function assetOrderCancel(data) {
  return post('/api/client/asset/order/cancel', data).then((res) => {
    return res
  })
}
// 10. 用户支付订单
export function assetOrderPay(data) {
  return post('/api/client/asset/order/pay', data).then((res) => {
    return res
  })
}
// 11. 用户支付结果查询
export function walletPayCheck(data) {
  return post('/api/client/wallet/pay/check', data).then((res) => {
    return res
  })
}

// 4.5 企业实名认证
export function enterpriseAuthentication(data) {
  return post('/api/client/user/enterprise/authentication', data).then((res) => {
    return res
  })
}

// 14. 订单详情
export function assetOrderInfo(data) {
  return get('/api/client/asset/order/info', data).then((res) => {
    return res
  })
}

// 22. 关联代理商
export function setUserAgentPublisher(data) {
  return post('/api/client/user/setUserAgentPublisher', data).then((res) => {
    return res
  })
}
